import { createUseStyles } from "react-jss"

import ContactForm from "components/ContactForm"
import Container from "components/Container"

const useStyles = createUseStyles({
  contactUs: {
    "& h2": {
      fontWeight: 500,
    },
    "& p": {
      margin: "1rem 0",
      fontSize: "18px",
    },
  },
})

const ContactUs = () => {
  const classes = useStyles()

  return (
    <Container className={classes.contactUs}>
      <h2>Contact Us</h2>
      <p>We look forward to hearing from you!</p>
      <p>
        Phone: <a href="tel:7075283277">(707) 528-3277</a>
      </p>
      <p>
        Email:{" "}
        <a href="email:dougmacawning@yahoo.com"> dougmacawning@yahoo.com</a>
      </p>
      <p>
        Located in Santa Rosa, CA. Operating in the greater Sonoma County area.
        For more info, please use the message form below.
      </p>
      <ContactForm />
    </Container>
  )
}

export default ContactUs
