import { createUseStyles } from "react-jss"

import dougAndSherri from "assets/doug-and-sherri.png"

import Container from "components/Container"

const useStyles = createUseStyles({
  aboutUs: {
    margin: "4rem 0",

    "& h1": {
      fontWeight: 500,
    },
    "& p": {
      margin: "1rem 0",
      fontSize: "18px",
    },
  },
  aboutUsColumns: {
    display: "flex",
    alignItems: "top",
    flexDirection: "column",

    "& p": {
      lineHeight: "1.5",
      fontWeight: 400,
    },

    "@media (min-width: 768px)": {
      flexDirection: "row",

      "& img": {
        marginRight: "4rem",
      },
    },
  },
  aboutUsPhoto: {
    "@media (min-width: 768px)": {
      maxWidth: "50%",
    },
  },
})

const AboutUs = () => {
  const classes = useStyles()

  return (
    <Container className={classes.aboutUs}>
      <h1>About Us</h1>

      <div className={classes.aboutUsColumns}>
        <img
          className={classes.aboutUsPhoto}
          src={dougAndSherri}
          alt="Doug and Sherri Cromley"
        />
        <div>
          <p>
            Welcome to our site! Doug Cromley, the owner of{" "}
            <a href="https://www.majestic-awning.com">
              Majestic Awning & Canopy
            </a>
            , began in the awning business in 1983. Family owned and operated,
            Majestic Awning & Canopy was established in 2000 and serves all of
            Sonoma County, as well as areas of Northern Marin and Southern
            Mendocino counties.
          </p>
          <p>
            We take great pride in our craftsmanship, timeliness and
            unparalleled customer service. Doug cares about his customers and
            that is apparent from your first call to us for a free estimate (or
            maybe just some advice about your current shade, rain or sun
            protection need).
          </p>
          <p>
            We look forward to hearing from you!{" "}
            <a href="tel:707-528-3277">Call us today!</a>
          </p>
        </div>
      </div>
    </Container>
  )
}

export default AboutUs
