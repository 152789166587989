import { createUseStyles } from "react-jss"

import { contactFormStr } from "./lib"

const useStyles = createUseStyles({
  contactForm: {},
})

const ContactForm = () => {
  const classes = useStyles()
  const contactForm = {
    __html: contactFormStr,
  }

  return (
    <div
      className={classes.contactForm}
      dangerouslySetInnerHTML={contactForm}
    />
  )
}

export default ContactForm
