import cx from "classnames"
import { createUseStyles } from "react-jss"

interface ContainerProps {
  children: React.ReactNode
  className?: string
  classNameInner?: string
}

const useStyles = createUseStyles({
  container: {},
  containerInner: {
    maxWidth: 1170,
    margin: "auto",
    padding: "0 15px",
  },
})

const Container: React.FC<ContainerProps> = ({
  children,
  className,
  classNameInner,
}) => {
  const styles = useStyles()

  return (
    <div className={cx(styles.container, className)}>
      <div className={cx(styles.containerInner, classNameInner)}>
        {children}
      </div>
    </div>
  )
}

export default Container
