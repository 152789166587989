import { createUseStyles } from "react-jss"
import Container from "./Container"

interface SlideItemProps {
  image: string
  caption?: string
}

const useStyles = createUseStyles({
  slideItem: {
    position: "relative",
  },
  slideItemImage: {
    width: "100%",
    height: "auto",
  },
  slideItemCaption: {
    padding: "10px 20px",
    margin: 0,
    color: "white",
    fontSize: 15,
    fontFamily: "Quicksand, sans-serif",
    fontStyle: "italic",

    "@media (min-width: 768px)": {
      fontSize: 30,
    },
  },
  slideItemCaptionContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
})

const SlideItem: React.FC<SlideItemProps> = ({ image, caption }) => {
  const classes = useStyles()

  return (
    <div className={classes.slideItem}>
      <img className={classes.slideItemImage} src={image} alt="" />
      {caption && (
        <Container className={classes.slideItemCaptionContainer}>
          <p className={classes.slideItemCaption}>{caption}</p>
        </Container>
      )}
    </div>
  )
}

export default SlideItem
