import { createUseStyles } from "react-jss"
import cx from "classnames"

import { lightBlue, darkBlue } from "./constants"
import Container from "./Container"
import logo from "assets/logo.png"
import NavItem from "./NavItem"

const useStyles = createUseStyles({
  nav: {
    backgroundColor: lightBlue,
    borderBottom: `5px solid ${darkBlue}`,
    color: "white",
    fontFamily: "Quicksand, sans-serif",
    padding: "10px 0",

    "@media (min-width: 768px)": {
      padding: "20px 0",
    },
  },
  title: {
    margin: 0,
    display: "flex",
    marginLeft: 10,
    fontSize: 19,

    "@media (min-width: 768px)": {
      maxWidth: 290,
      fontSize: 36,
    },
  },
  logo: {
    width: 50,
    height: "auto",

    "@media (min-width: 768px)": {
      width: 90,
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  flexMain: {
    justifyContent: "space-between",
    flexDirection: "column",

    "@media (min-width: 768px)": {
      flexDirection: "row",
    },
  },
  navLinks: {
    marginBottom: 10,

    "& span": {
      fontSize: 18,
    },

    "@media (min-width: 768px)": {
      "& span": {
        fontSize: 26,
      },
    },
  },
  navSubtitle: {
    fontSize: 14,
    textAlign: "right",
    paddingRight: 10,
  },
})

const Nav = () => {
  const classes = useStyles()

  return (
    <nav className={classes.nav}>
      <Container>
        <div className={cx(classes.flex, classes.flexMain)}>
          <div className={classes.flex}>
            <div>
              <img
                className={classes.logo}
                src={logo}
                alt="Majestic Awning Logo"
              />
            </div>
            <h1 className={classes.title}>Majestic Awning & Canopy</h1>
          </div>

          <div>
            <div className={cx(classes.flex, classes.navLinks)}>
              <NavItem href="/">
                <span>Home</span>
              </NavItem>
              <NavItem href="/about-us">
                <span>About Us</span>
              </NavItem>
              <NavItem href="/contact-us">
                <span>Contact Us</span>
              </NavItem>
            </div>
            <div className={classes.navSubtitle}>
              CA Contractors Lic #787399
            </div>
          </div>
        </div>
      </Container>
    </nav>
  )
}

export default Nav
