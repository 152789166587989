import { createUseStyles } from "react-jss"
import Container from "components/Container"

const useStyles = createUseStyles({
  disclaimer: {
    margin: "4rem 0 8rem",
    "& h1": {
      fontWeight: 500,
    },
    "& ol": {
      listStyle: "decimal",
      margin: "1rem 0",
      padding: "0 0 0 2rem",
      "& li": {
        margin: "1rem 0",
        "& h2": {
          fontWeight: 500,
        },
        "& p": {
          margin: "1rem 0",
          fontSize: "18px",
        },
      },
    },
  },
})

const Disclaimer = () => {
  const classes = useStyles()

  return (
    <Container className={classes.disclaimer}>
      <h1>Disclaimer</h1>
      <ol>
        <li>
          <h2>Acceptance of our terms</h2>
          <p>
            By visiting the website Majestic Awning, viewing, accessing or
            otherwise using any of the services or information created,
            collected, compiled or submitted to Majestic Awning, you agree to be
            bound by the following Terms and Conditions of Service. If you do
            not want to be bound by our Terms your only option is not to visit,
            view or otherwise use the services of Majestic Awning. You
            understand, agree and acknowledge that these Terms constitute a
            legally binding agreement between you and Majestic Awning and that
            your use of Majestic Awning shall indicate your conclusive
            acceptance of this agreement.
          </p>
        </li>
        <li>
          <h2>Provision of Services</h2>
          <p>
            You agree and acknowledge that Majestic Awning is entitled to
            modify, improve or discontinue any of its services at its sole
            discretion and without notice to you even if it may result in you
            being prevented from accessing any information contained in it.
            Furthermore, you agree and acknowledge that Majestic Awning is
            entitled to provide services to you through subsidiaries or
            affiliated entities.
          </p>
        </li>
        <li>
          <h2>Proprietary Rights</h2>
          <p>
            You acknowledge and agree that Majestic Awning may contain
            proprietary and confidential information including trademarks,
            service marks and patents protected by intellectual property laws
            and international intellectual property treaties. Majestic Awning
            authorizes you to view and make a single copy of portions of its
            content for offline, personal, non-commercial use. Our content may
            not be sold, reproduced, or distributed without our written
            permission. Any third-party trademarks, service marks and logos are
            the property of their respective owners. Any further rights not
            specifically granted herein are reserved.
          </p>
        </li>
        <li>
          <h2>Submitted Content</h2>
          <p>
            When you submit content to Majestic Awning you simultaneously grant
            Majestic Awning an irrevocable, worldwide, royalty-free license to
            publish, display, modify, distribute and syndicate your content
            worldwide. You confirm and warrant that you have the required
            authority to grant the above license to Majestic Awning
          </p>
        </li>
        <li>
          <h2>Termination of Agreement</h2>
          <p>
            The Terms of this agreement will continue to apply in perpetuity
            until terminated by either party without notice at any time for any
            reason. Terms that are to continue in perpetuity shall be unaffected
            by the termination of this agreement.
          </p>
        </li>
        <li>
          <h2>Disclaimer of Warranties</h2>
          <p>
            You understand and agree that your use of Majestic Awning is
            entirely at your own risk and that our services are provided “As Is”
            and “As Available”. Majestic Awning does not make any express or
            implied warranties, endorsements or representations whatsoever as to
            the operation of the Majestic Awning website, information, content,
            materials, or products. This shall include, but not be limited to,
            implied warranties of merchantability and fitness for a particular
            purpose and non-infringement, and warranties that access to or use
            of the service will be uninterrupted or error-free or that defects
            in the service will be corrected.
          </p>
        </li>
        <li>
          <h2>Limitation of Liability</h2>
          <p>
            You understand and agree that Majestic Awning and any of its
            subsidiaries or affiliates shall in no event be liable for any
            direct, indirect, incidental, consequential, or exemplary damages.
            This shall include, but not be limited to damages for loss of
            profits, business interruption, business reputation or goodwill,
            loss of programs or information or other intangible loss arising out
            of the use of or the inability to use the service, or information,
            or any permanent or temporary cessation of such service or access to
            information, or the deletion or corruption of any content or
            information, or the failure to store any content or information. The
            above limitation shall apply whether or not Majestic Awning has been
            advised of or should have been aware of the possibility of such
            damages. In jurisdictions where the exclusion or limitation of
            liability for consequential or incidental damages is not allowed the
            liability of Majestic Awning is limited to the greatest extent
            permitted by law.
          </p>
        </li>
        <li>
          <h2>External Content</h2>
          <p>
            Majestic Awning may include hyperlinks to third-party content,
            advertising or websites. You acknowledge and agree that Majestic
            Awning is not responsible for and does not endorse any advertising,
            products or resource available from such resources or websites.
          </p>
        </li>
        <li>
          <h2>Jurisdiction</h2>
          <p>
            You expressly understand and agree to submit to the personal and
            exclusive jurisdiction of the courts of the country, state, province
            or territory determined solely by Majestic Awning to resolve any
            legal matter arising from this agreement or related to your use of
            Majestic Awning. If the court of law having jurisdiction, rules that
            any provision of the agreement is invalid, then that provision will
            be removed from the Terms and the remaining Terms will continue to
            be valid.
          </p>
        </li>
        <li>
          <h2>Entire Agreement</h2>
          <p>
            You understand and agree that the above Terms constitute the entire
            general agreement between you and Majestic Awning.You may be subject
            to additional Terms and conditions when you use, purchase or access
            other services, affiliate services or third-party content or
            material.
          </p>
        </li>
        <li>
          <h2>Changes to the Terms</h2>
          <p>
            Majestic Awning reserves the right to modify these Terms from time
            to time at our sole discretion and without any notice. Changes to
            our Terms become effective on the date they are posted and your
            continued use of Majestic Awning after any changes to Terms will
            signify your agreement to be bound by them.
          </p>
        </li>
      </ol>
    </Container>
  )
}

export default Disclaimer
