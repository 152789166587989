import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"

interface NavItemProps {
  children: React.ReactNode
  href: string
}

const useStyles = createUseStyles({
  navItem: {},
  navLink: {
    color: "white",
    textDecoration: "none",
    fontFamily: "Oswald, sans-serif",
    textTransform: "uppercase",
    fontSize: 26,
    padding: "0 10px",
  },
})

const NavItem: React.FC<NavItemProps> = ({ children, href }) => {
  const styles = useStyles()

  return (
    <div className={styles.navItem}>
      <Link className={styles.navLink} to={href}>
        {children}
      </Link>
    </div>
  )
}

export default NavItem
