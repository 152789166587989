import { createUseStyles } from "react-jss"
import ContactForm from "./ContactForm"

import Container from "./Container"

const useStyles = createUseStyles({
  footer: {
    backgroundColor: "#333",
    borderTop: "5px solid #858585",
    color: "#fff",
  },
  footerInner: {
    background:
      "linear-gradient(to bottom, rgba(255,255,255,0.2) 0%, rgba(0,0,0,0.2) 100%)",
    paddingTop: "1rem",

    "@media (min-width: 768px)": {
      paddingTop: 0,
    },
  },
  columns: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",

    "@media (min-width: 768px)": {
      flexDirection: "row",
    },
  },
  column: {
    flex: 1,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0 1rem",
    margin: "1rem 0",

    "& h2": {
      margin: "0 0 1.5rem",
    },

    "& ul": {
      listStyle: "none",
      padding: 0,
    },

    "@media (min-width: 768px)": {
      margin: "4rem 0",
    },
  },
  footerCopyright: {
    textAlign: "center",
    padding: "0 0 4rem",
  },
})

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <div className={classes.footerInner}>
        <Container>
          <div className={classes.columns}>
            <div className={classes.column}>
              <h2>Categories</h2>

              <ul>
                <li>
                  <a href="/">Majestic Awning & Canopy</a>
                </li>
                <li>
                  <a href="/about-us">About Us</a>
                </li>
                <li>
                  <a href="/contact-us">Contact Us</a>
                </li>
                <li>
                  <a href="/disclaimer">Disclaimer</a>
                </li>
              </ul>
            </div>
            <div className={classes.column}>
              <h2>About Us</h2>
              <p>
                <a href="https://majestic-awning.com">
                  Majestic Awning & Canopy
                </a>{" "}
                of Santa Rosa, CA offers quality awnings, shades, covers, and
                more for reasonable prices. Family owned and operated since
                2000. CA Contractors Lic #787399
              </p>
            </div>
            <div className={classes.column}>
              <h2>Contact Us</h2>
              <ContactForm />
            </div>
          </div>
        </Container>

        <div className={classes.footerCopyright}>
          &copy; 2000-{new Date().getFullYear()} Majestic Awning & Canopy
        </div>
      </div>
    </footer>
  )
}

export default Footer
