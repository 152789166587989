import Slider from "react-slick"
import SlideItem from "./SlideItem"

import { slides } from "components/lib"

const Slideshow = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
  }
  return (
    <div>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <SlideItem
            key={index}
            image={slide.image16x9}
            caption={slide.caption}
          />
        ))}
      </Slider>
    </div>
  )
}

export default Slideshow
