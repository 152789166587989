import { createUseStyles } from "react-jss"

import { accentBlue } from "components/constants"
import Container from "components/Container"
import Reviews from "components/Reviews"
import Slideshow from "components/Slider"

import radius from "assets/radius.png"
import family from "assets/doug-and-sherri.png"

const useStyles = createUseStyles({
  ctaRow: {
    padding: "28px 0",
    backgroundColor: "#ddd",
    color: "#444",
    textAlign: "center",
    borderBottom: "5px solid #d4d4d4",
    marginTop: "35px",
  },
  ctaRowTitle: {
    margin: "0",
    fontWeight: 500,
    fontSize: "22px",
    padding: "0 1rem",

    "@media (min-width: 768px)": {
      fontSize: "30px",
    },
  },
  ctaRowLink: {
    color: `${accentBlue}`,
    textDecoration: "none",
  },
  columns: {
    display: "flex",
    flexDirection: "column",

    "@media (min-width: 768px)": {
      margin: "4rem -1rem",
      flexDirection: "row",
    },
  },
  column: {
    flex: 1,
    padding: "1rem",

    "@media (min-width: 768px)": {
      padding: "0 1rem",
    },
  },
  columnTitle: {
    textAlign: "center",
    fontSize: "22px",
    margin: "0 0 1.5rem",
    fontWeight: 500,

    "@media (min-width: 768px)": {
      fontSize: "30px",
      fontWeight: 800,
    },
  },
  columnParagraph: {
    textAlign: "center",
    fontSize: "17px",
    fontWeight: 400,

    "@media (min-width: 768px)": {
      fontSize: "21px",
    },
  },
  columnSubtext: {
    textAlign: "center",
    marginTop: "0.75rem",
  },
  mapImage: {
    width: "100%",
    height: "auto",
  },
  familyImage: {
    width: "100%",
    height: "auto",
  },
})

const Home = () => {
  const classes = useStyles()

  return (
    <div>
      <Slideshow />

      <div className={classes.ctaRow}>
        <h2 className={classes.ctaRowTitle}>
          Have questions? Call us at{" "}
          <a className={classes.ctaRowLink} href="tel:7075283277">
            707-528-3277
          </a>
        </h2>
      </div>

      <Container>
        <div className={classes.columns}>
          <div className={classes.column}>
            <h3 className={classes.columnTitle}>Family Owned Business</h3>
            <p>
              <img
                className={classes.familyImage}
                src={family}
                alt="Family Owned"
              />
            </p>
          </div>

          <div className={classes.column}>
            <h3 className={classes.columnTitle}>Welcome!</h3>
            <p className={classes.columnParagraph}>
              Family-owned high quality custom work since 2000. We offer a wide
              range of awnings, covers, shades, and more!
            </p>
            <p className={classes.columnParagraph}>
              Give us a call, we're happy to help!
            </p>
          </div>

          <div className={classes.column}>
            <h3 className={classes.columnTitle}>Contact Us</h3>
            <img className={classes.mapImage} src={radius} alt="" />
            <p className={classes.columnSubtext}>
              We serve most of the North Bay area.
            </p>
          </div>
        </div>
      </Container>

      <Reviews />
    </div>
  )
}

export default Home
