import { createUseStyles } from "react-jss"

import { veryLightBlue } from "components/constants"
import Container from "./Container"

const useStyles = createUseStyles({
  reviews: {
    backgroundColor: `${veryLightBlue}`,
    padding: "40px 0 50px",
  },
  review: {
    margin: "auto",
    textAlign: "center",
    maxWidth: "66%",
  },
  stars: {
    color: "#ffd700",
    textShadow: "0 1px 2px rgba(159,120,0,0.9)",
  },
  footer: {
    display: "block",
    fontSize: "80%",
    lineHeight: "1.42857143",
    color: "#777",
  },
})

const Reviews = () => {
  const classes = useStyles()

  return (
    <div className={classes.reviews}>
      <Container>
        <blockquote
          className={classes.review}
          cite="https://goo.gl/maps/qHRdwEsJV732"
        >
          <h2 className={classes.stars}>★★★★★</h2>
          <p>
            Great service, Doug had to customize the awning to allow access
            above. Everything turned out great, very professional job. on time
            on budget.
          </p>
          <footer className={classes.footer}>&mdash; Mike B.</footer>
        </blockquote>
      </Container>
    </div>
  )
}

export default Reviews
